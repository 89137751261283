import React, { useState, useEffect, useRef } from 'react';
import {
	useQuery,
	useLazyQuery,
	useMutation,
} from '@apollo/client/react/hooks';
import { connect } from 'react-redux';
import * as Sentry from '@sentry/browser';
import moment from 'moment';
import convert from 'htmr';

// External Components
import {
	DropdownButton,
	Dropdown,
	Spinner,
	Badge,
	Container,
	Row,
	Col,
} from 'react-bootstrap';

// Internal Components
import {
	PublishEpisodeModal,
	ShareContentModal,
	Button,
	ConfirmationModal,
	CastedModal,
	YoutubeForm,
} from 'components/shared';

// Internal Libraries
import queries from 'services/graphql/queries';
import mutations from 'services/graphql/mutations';
import Toast from 'services/toast';
import { capitalize, formatDuration } from 'utils';
import { getSelectedAccount } from 'store/selectors/accounts';
import { trackEvent } from 'services/vitally';
import { dispatch } from 'store/store';
import { setEpisode } from 'store/reducers/data/episode';
import {
	getContentType,
	EPISODE_UPLOAD_TYPES,
	EPISODE_PUBLIC_STATUS,
	SHARE_MODAL_TYPE,
	PODCAST_COLLECTION_TYPE,
} from 'components/constants';
import { getPermission, ObjectPermissionSlugs } from 'utils/permissionsManager';

const EpisodeStatus = (props) => {
	// Props
	const {
		episodeId,
		accountId,
		episode,
		podcast,
		permissions,
		selectedAccount,
		youtubeIntegration,
	} = props;

	const { slug: episodeSlug, publicStatus, storageLink } = episode;
	const {
		slug: podcastSlug,
		customDomain,
		externalId: hosted,
		collectionType,
		autoPublishYoutube,
	} = podcast;

	const episodeContentType = getContentType(podcast.collectionType);

	const cardText =
		episode.metaDescription || episode.showNotes || episode.description;

	const episodePermission = permissions
		? getPermission(permissions, ObjectPermissionSlugs.EPISODES, podcast.id)
				.rolePermission
		: {};

	const publishPermission = (permissions) => {
		const fetchPermissions = getPermission(
			permissions,
			ObjectPermissionSlugs.PUBLISH,
			podcast.id
		);

		if (fetchPermissions && fetchPermissions?.rolePermission) {
			return fetchPermissions.rolePermission;
		} else {
			return {};
		}
	};

	const [publishToYoutube] = useMutation(mutations.publishToYoutube, {
		refetchQueries: ['EpisodeById'],
  	});

	const doPublishToYoutube = async () => {
		await publishToYoutube({
			variables: {
				episodeId,
				accountId: parseInt(accountId, 10),
				youtubeEpisode: {
					title: episode.name,
					description: episode.youtubeDescription || episode.description,
					thumbnail: episode.youtubeThumbnail || episode.thumbnail || podcast.thumbnail,
					visibility: 'private'
				},
			},
		});

		Toast.info(
			'Upload to YouTube is initiated',
			<span>You can check it on YouTube Studio after some time!</span>,
			{ autoClose: false, closeOnClick: true }
		);
	}


	// State
	const [isLoading, setIsLoading] = useState(false);
	const [desiredStatus, setDesiredStatus] = useState(null);
	const [isScheduleModalOpen, setIsScheduleModalOpen] = useState(false);
	const [isShareModalOpen, setIsShareModalOpen] = useState(false);
	const [confirmUnpublish, setConfirmUnpublish] = useState(false);

	const [confirmYoutubePublish, setConfirmYoutubePublish] = useState(false);
	const [showYoutubeModal, setShowYoutubeModal] = useState(false);
	const [showYoutubeVisibilityModal, setShowYoutubeVisibilityModal] =
		useState(false);
	const [publishPrivate, setPublishPrivate] = useState(false);

	// Need useRef here so the polling function has access to the isLoading value
	// https://github.com/facebook/react/issues/14010
	const loadingRef = useRef(isLoading);
	loadingRef.current = isLoading;

	/**
	 * Query Schedule Details
	 */
	const { data: scheduleData } = useQuery(queries.checkPublishSchedule, {
		variables: { episodeId },
		skip: !hosted,
	});
	const { checkPublishSchedule = {} } = scheduleData || {};
	const { runAt: publishDate, id: jobId } = checkPublishSchedule || {};

	const [
		getEpisode,
		{ data: episodeData, refetch: refetchEpisode, called: shouldRefetch },
	] = useLazyQuery(queries.episodeById, {
		variables: {
			episodeId: episode.id,
			podcastId: episode.podcastId,
			includeThemeMatches: true,
		},
		fetchPolicy: 'network-only',
	});

	/**
	 * PublicStatus is set by a very async process
	 * where the jobs service will complete tasks
	 * before the episode table is updated,
	 * This means we need to wait for the change
	 * to public status via polling
	 */
	const startWaiting = (newStatus) => {
		setDesiredStatus(newStatus);
		setIsLoading(true);
	};
	const stopWaiting = () => {
		setDesiredStatus(null);
		setIsLoading(false);
	};

	useEffect(() => {
		if (episodeData?.episode) {
			const updatedStatus = episodeData.episode.publicStatus;

			if (
				(desiredStatus === EPISODE_PUBLIC_STATUS.PUBLIC &&
					updatedStatus === EPISODE_PUBLIC_STATUS.PUBLIC) ||
				(desiredStatus === EPISODE_PUBLIC_STATUS.SCHEDULED &&
					updatedStatus === EPISODE_PUBLIC_STATUS.DRAFT &&
					publishDate) ||
				(desiredStatus === EPISODE_PUBLIC_STATUS.PRIVATE &&
					updatedStatus === EPISODE_PUBLIC_STATUS.PRIVATE) ||
				(desiredStatus === EPISODE_PUBLIC_STATUS.DRAFT &&
					updatedStatus === EPISODE_PUBLIC_STATUS.DRAFT &&
					checkPublishSchedule === null)
			) {
				stopWaiting();
				dispatch(setEpisode(episodeData.episode));
			}
		}
	}, [checkPublishSchedule, episodeData]);

	useEffect(() => {
		if (isLoading) {
			pollForStatusChange(1000);
		}
	}, [isLoading]);

	/**
	 * Manage Publish / Unpublishing Episode
	 */
	// Move episode back to Draft
	const [unpublishEpisode, { data: unpublishData }] = useMutation(
		mutations.unPublishEpisode,
		{
			variables: { episodeId, jobId },
			onError: (err) => {
				console.error(err);
				Toast.error('Unable to Change Publish Status');
				Sentry.captureException(err);
				stopWaiting();
			},
			refetchQueries: ['checkPublishSchedule'],
		}
	);
	// Publish or schedule an episode
	const [
		publishEpisode,
		{ data: publishData, loading: publishLoading, error: publishError },
	] = useMutation(mutations.publishEpisode, {
		onError: (err) => {
			console.error(err);
			Toast.error('Unable to Publish Episode');
			Sentry.captureException(err);
			stopWaiting();
		},
		refetchQueries: ['checkPublishSchedule'],
	});

	const switchToDraft = () => {
		startWaiting(EPISODE_PUBLIC_STATUS.DRAFT);
		unpublishEpisode();
		trackEvent('unpublish-item', {
			itemName: episode.name,
			collectionName: podcast.name,
		});
		setConfirmUnpublish(false);
	};

	const publishContent = (
		publicStatus = EPISODE_PUBLIC_STATUS.PUBLIC,
		youtubeVisibility
	) => {
		startWaiting(publicStatus);
		publishEpisode({
			variables: {
				episodeId,
				setPrivate: publicStatus === EPISODE_PUBLIC_STATUS.PRIVATE,
				youtubeVisibility,
			},
		});
		trackEvent('publish-item', {
			itemName: episode.name,
			collectionName: podcast.name,
			publishDate: 'Now',
			private: publicStatus === EPISODE_PUBLIC_STATUS.PRIVATE,
		});
		setPublishPrivate(false);
	};

	const openYoutubeVisibiltyModal = () => {
		setShowYoutubeVisibilityModal(true);
	};

	const closeYoutubeVisibilityModal = (visibility) => {
		setShowYoutubeVisibilityModal(false);
		publishContent(
			publishPrivate
				? EPISODE_PUBLIC_STATUS.PRIVATE
				: EPISODE_PUBLIC_STATUS.PUBLIC,
			visibility
		);
	};

	// Sharing urls
	const publicUrl = customDomain
		? `https://${customDomain}`
		: `${process.env.REACT_APP_PUBLIC_APP_ROOT}`;
	const shareUrl = `${publicUrl}/public/${accountId}/${podcastSlug}/${episodeSlug}`;

	// Poor man's Exponential Backoff to avoid self DDOS while checking publish status
	const pollForStatusChange = (interval) => {
		if (loadingRef.current) {
			if (shouldRefetch) {
				refetchEpisode();
			} else {
				getEpisode();
			}
			const newInterval = interval > 60000 ? 1000 : interval * 2;
			setTimeout(() => pollForStatusChange(newInterval), newInterval);
		}
	};

	/**
	 * Render Functions
	 * Builds the buttons based on public status
	 * and if Podcast is hosted, etc
	 */
	// Episode Name and Status Badge
	const renderEpisodeNameAndStatus = () => {
		const statusText =
			publicStatus === EPISODE_PUBLIC_STATUS.DRAFT && publishDate
				? 'Scheduled'
				: capitalize(publicStatus);
		return (
			<>
				<h3 className="episode-header--name">
					{episode.name}{' '}
					<Badge variant="info" pill>
						{statusText}
					</Badge>
				</h3>
				{publicStatus === EPISODE_PUBLIC_STATUS.PUBLIC ? (
					<p className="episode-header--pub-date">
						Published on{' '}
						{moment(episode.publishedAt).format('MMM D, YYYY')}
					</p>
				) : null}
			</>
		);
	};

	// Link to the public page
	const renderViewLink = () => {
		const viewText =
			publicStatus === EPISODE_PUBLIC_STATUS.DRAFT
				? `Preview ${episodeContentType}`
				: `View ${episodeContentType} Page`;

		let viewSlug = `/public/${accountId}/${podcastSlug}/${episodeSlug}`;
		// NextJS decodes query string params, so need to double-encode the slug
		const viewUrl = `${publicUrl}/api/preview?secret=${episodeId}&slug=${encodeURIComponent(
			viewSlug
		)}`;

		return (
			<div className="episode-header--view">
				<a className="ext-link" target="_blank" href={viewUrl}>
					{viewText}
				</a>
			</div>
		);
	};

	// Button to publish or move back to draft
	const renderShareButton = () => {
		return (
			<div className="episode-status--schedule">
				<Button
					variant="light"
					className="episode-header--button"
					onClick={() => {
						setIsShareModalOpen(true);
					}}
				>
					Share
				</Button>
			</div>
		);
	};

	// Button to schedule, publish, or edit schedule
	const renderScheduleButton = () => {
		if (
			!hosted ||
			!episodePermission.canEdit ||
			!publishPermission(permissions).canEdit
		)
			return null;
		if (isLoading) {
			return (
				<Button variant="light" className="episode-header--button">
					<Spinner animation="border" size="sm" />
				</Button>
			);
		}
		// Already Scheduled
		if (publishDate && publicStatus === EPISODE_PUBLIC_STATUS.SCHEDULED) {
			const scheduleDate = `Scheduled For ${moment(publishDate).format(
				'MMM D, YYYY'
			)}`;
			return (
				<div className="episode-status--schedule">
					<DropdownButton
						title={scheduleDate}
						className="episode-header--button"
						variant="success"
					>
						<Dropdown.Item
							onClick={() => {
								setIsScheduleModalOpen(true);
							}}
						>
							Edit Publish Date
						</Dropdown.Item>
						<Dropdown.Item
							onClick={() => {
								autoPublishYoutube && episode.youtubeId !== null
									? openYoutubeVisibiltyModal()
									: publishContent();
							}}
						>
							Publish Now
						</Dropdown.Item>
						<Dropdown.Item
							onClick={() => {
								startWaiting(EPISODE_PUBLIC_STATUS.DRAFT);
								unpublishEpisode();
								trackEvent('unpublish-item', {
									itemName: episode.name,
									collectionName: podcast.name,
								});
							}}
						>
							Cancel
						</Dropdown.Item>
					</DropdownButton>
				</div>
			);
		}
		// Not Yet Scheduled
		if (
			!publishDate &&
			publicStatus !== EPISODE_PUBLIC_STATUS.PUBLIC &&
			publicStatus !== EPISODE_PUBLIC_STATUS.PRIVATE
		) {
			return (
				<div className="episode-status--schedule">
					<DropdownButton
						title="Schedule"
						className="episode-header--button"
						variant="success"
						disabled={!storageLink}
					>
						<Dropdown.Item
							onClick={() => {
								setIsScheduleModalOpen(true);
							}}
						>
							Schedule
						</Dropdown.Item>
						<Dropdown.Item
							onClick={() => {
								autoPublishYoutube && episode.youtubeId === null
									? openYoutubeVisibiltyModal()
									: publishContent();
							}}
						>
							Publish Now
						</Dropdown.Item>
						{youtubeIntegration &&
							youtubeIntegration.status === 1 &&
							episode.uploadType === EPISODE_UPLOAD_TYPES.VIDEO &&
							!autoPublishYoutube && (
								<Dropdown.Item
									onClick={async () =>  {
										const ytD = episode.youtubeDescription || false;
										const ytT = episode.youtubeThumbnail || false;

										if(!ytD || !ytT) {
											setConfirmYoutubePublish(true)
										} else {
											await doPublishToYoutube();
										}
									}
									}
									disabled={episode.youtubeId !== null}
								>
									Publish To YouTube
								</Dropdown.Item>
							)}
						<Dropdown.Item
							onClick={() => {
								setPublishPrivate(true);
								autoPublishYoutube && episode.youtubeId === null
									? openYoutubeVisibiltyModal()
									: publishContent(
											EPISODE_PUBLIC_STATUS.PRIVATE
									  );
							}}
						>
							Publish as Private
						</Dropdown.Item>
					</DropdownButton>
				</div>
			);
		}

		// Published
		else {
			return (
				<div className="episode-status--schedule">
					<Button
						variant="light"
						className="episode-header--button"
						onClick={() => {
							setConfirmUnpublish(true);
						}}
					>
						Switch to Draft
					</Button>
				</div>
			);
		}
	};

	/**
	 * Render!
	 */
	return (
		<>
			<Container fluid className="episode-detail--status">
				<Row>
					<Col className="p-0">{renderEpisodeNameAndStatus()}</Col>
					<Col className="p-0 d-flex justify-content-end align-items-center">
						{renderViewLink()}
						{renderShareButton()}
						{renderScheduleButton()}
					</Col>
				</Row>
			</Container>

			<PublishEpisodeModal
				isOpen={isScheduleModalOpen}
				onClose={() => setIsScheduleModalOpen(false)}
				title="Schedule This Episode"
				episodeId={episodeId}
				autoPublishYoutube={
					autoPublishYoutube && episode.youtubeId === null
				}
				publishDateTime={moment.tz(publishDate, 'UTC').toISOString()}
				onSchedule={async (publishDateTime, youtubeVisibility) => {
					startWaiting(EPISODE_PUBLIC_STATUS.SCHEDULED);
					await publishEpisode({
						variables: {
							episodeId,
							publishOn: publishDateTime,
							youtubeVisibility,
						},
					});
					trackEvent('publish-item', {
						itemName: episode.name,
						collectionName: podcast.name,
						publishDate: publishDateTime,
					});
					setIsScheduleModalOpen(false);
				}}
			/>

			<ShareContentModal
				isOpen={isShareModalOpen}
				onClose={() => {
					setIsShareModalOpen(false);
				}}
				publicStatus={publicStatus}
				title={`Share ${
					episodeContentType[0].toUpperCase() +
					episodeContentType.substring(1)
				}`}
				shareUrl={shareUrl}
				shareContentName={episode.name}
				shareContentSubtitle={`Episode ${
					episode.episode
				} | ${formatDuration(episode.duration, 'seconds', true)}`}
				shareContentThumbnail={episode.thumbnail || podcast.thumbnail}
				videoEnabled={
					selectedAccount.enableVideo && episode.videoLink
						? true
						: false
				}
				baseUrl={publicUrl}
				contentSlug={episodeSlug}
				renderMoreContent={() => (
					<div>{cardText ? convert(cardText) : ''}</div>
				)}
				accountId={accountId}
				showSlug={podcast.slug}
				shareId={episode.id}
				name={episode.name}
				description={
					podcast.collectionType === PODCAST_COLLECTION_TYPE.serial
						? episode.description
						: episode.showNotes
				}
				thumbnail={episode.thumbnail}
				showThumbnail={podcast.thumbnail}
				showPublishToYoutube={
					youtubeIntegration &&
					youtubeIntegration.status === 1 &&
					episode.uploadType === EPISODE_UPLOAD_TYPES.VIDEO &&
					episode.youtubeId === null
				}
				shareModalType={SHARE_MODAL_TYPE.EPISODE}
				linkText="This link can be shared on social media, see the preview below."
				embedText="Embed an episode player on your website. There are three different types of Embeds for this episode. Select an embed type and options below and view the embed preview on the right:"
				socialText="Utilize the Social media embed feature to share your episodes directly onto your LinkedIn timeline"
				youtubeText="This link can be shared on social media, see the preview below."
				disableLinkSharing={podcast.disableLandingPages}
			/>

			<ConfirmationModal
				isOpen={confirmUnpublish}
				title="Switch to Draft"
				confirmationText="Are you sure you want to unpublish this episode? It will be removed from the RSS feed and all public pages."
				confirmationButtonLabel="UNPUBLISH"
				onCancel={() => setConfirmUnpublish(false)}
				onConfirm={switchToDraft}
			/>


			<ConfirmationModal
				isOpen={confirmYoutubePublish}
				title="Publish to Youtube"
				confirmationText="Are you sure you want to publish this episode? Make sure Youtube description and thumbail fields are set."
				confirmationButtonLabel="PUBLISHTOYOUTUBE"
				onCancel={() => setConfirmYoutubePublish(false)}
				onConfirm={async () => {
					await doPublishToYoutube();
					setConfirmYoutubePublish(false);
				}}
			/>


			<CastedModal
				className="upload-wizard-modal"
				show={showYoutubeModal || showYoutubeVisibilityModal}
				handleClose={() => {
					showYoutubeVisibilityModal
						? setShowYoutubeVisibilityModal(false)
						: setShowYoutubeModal(false);
				}}
			>
				<YoutubeForm
					accountId={accountId}
					episodeId={episode.id}
					title={episode.name}
					description={
						podcast.collectionType ===
						PODCAST_COLLECTION_TYPE.serial
							? episode.description
							: episode.showNotes
					}
					thumbnail={episode.thumbnail}
					showThumbnail={podcast.thumbnail}
					autoPublishYoutube={
						showYoutubeVisibilityModal &&
						autoPublishYoutube &&
						episode.youtubeId === null
					}
					onClose={
						showYoutubeVisibilityModal
							? closeYoutubeVisibilityModal
							: () => setShowYoutubeModal(false)
					}
				/>
			</CastedModal>
		</>
	);
};

const mapStateToProps = (state) => ({
	selectedAccount: getSelectedAccount(state),
	permissions: state.auth.permissions,
});

export default connect(mapStateToProps)(EpisodeStatus);
